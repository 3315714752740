export function saveIdentity(identity: string): void {
  localStorage.setItem("identity", identity);
}

export function getIdentity(): string | null {
  return localStorage.getItem("identity");
}

export function isIdentityStored(): boolean {
  return "identity" in localStorage;
}
