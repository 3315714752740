import { Line, TractionType } from "./entities";

const humanMap = new Map<TractionType, string>([
  [TractionType.Tram, "Tramvaje"],
  [TractionType.Trolleybus, "Trolejbusy"],
  [TractionType.Bus, "Autobusy"],
  [TractionType.Night, "Noční linky"]
]);

const tractionTypeOrder = [TractionType.Tram, TractionType.Trolleybus, TractionType.Bus];

export function tractionTypeToHuman(type: TractionType): string {
  return humanMap.get(type);
}

export function tractionTypeToClass(type: TractionType): string {
  return TractionType[type].toLowerCase();
}

export function groupLines(input: Line[]): Map<TractionType, Line[]> {
  let output = new Map<TractionType, Line[]>();

  for (let line of sortLinesByTractionType(input)) {
    if (output.has(line.type)) output.get(line.type).push(line);
    else output.set(line.type, [line]);
  }

  return output;
}

export function sortLines(input: Line[]) {
  return input.sort((a, b) => {
    let aIsDigit = /^\d+$/.test(a.name);
    let bIsDigit = /^\d+$/.test(b.name);

    if (!aIsDigit || !bIsDigit) {
      return a.name.localeCompare(b.name);
    } else if (aIsDigit && bIsDigit) {
      return Number(a.name) - Number(b.name);
    } else if (aIsDigit) {
      return -1;
    } else if (bIsDigit) {
      return 1;
    } else return 0;
  });
}

export function sortLinesByTractionType(input: Line[]) {
  return input.sort((a, b) => {
    let aIndex = tractionTypeOrder.indexOf(a.type);
    let bIndex = tractionTypeOrder.indexOf(b.type);

    if (aIndex == -1) return 1;
    if (bIndex == -1) return -1;

    return aIndex - bIndex;
  });
}
