import { Line } from "./entities";

export const base = "https://mhd.akac.workers.dev/api/";
export let identity: string = null;

export function saveIdentity(input: string): void {
  identity = input;
}

export async function request(path: string, init?: RequestInit): Promise<Response> {
  if (identity) {
    if (!init) init = {};
    if (!init.headers) init.headers = {};
    init.headers = {
      ...init.headers,
      Identity: identity
    };
  }

  return await fetch(`${base}${path}`, init);
}

export async function verify(input: string): Promise<boolean> {
  let res = await request("verify", {
    method: "POST",
    body: input,
    headers: {
      "Content-Type": "text/plain; charset=utf-8"
    }
  });

  return (await res.text()) == "true";
}

export async function getAll(): Promise<Line[]> {
  let res = await request("get/all");

  return (await res.json()) as Line[];
}

export async function set(line: Line): Promise<void> {
  await request("set", {
    method: "POST",
    body: JSON.stringify(line),
    headers: {
      "Content-Type": "application/json; charset=utf-8"
    }
  });
}
