export type Line = {
  name: string;
  type: TractionType;
  date?: Date;
  notes?: string;
  completed: boolean;
};

export enum TractionType {
  Tram = 1,
  Trolleybus = 2,
  Bus = 3,
  Night = 4
}
