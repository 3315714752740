import { Line, TractionType } from "./entities";
import * as Dom from "./dom";
import * as Extensions from "./extensions";
import * as Events from "./events";

export function addVehicle(type: TractionType, lines: Line[]): void {
  let vehicleClone = (Dom.templates.vehicleView.content.cloneNode(true) as Element).firstElementChild as HTMLDivElement;

  vehicleClone.querySelector("header").textContent = Extensions.tractionTypeToHuman(type);

  for (let line of lines) {
    let lineClone = (Dom.templates.lineView.content.cloneNode(true) as Element).firstElementChild as HTMLDivElement;

    lineClone.classList.add(Extensions.tractionTypeToClass(type));

    lineClone.dataset.completed = line.completed.toString();
    lineClone.querySelector("span").textContent = line.name;
    lineClone.querySelector("img").src = `/assets/${Extensions.tractionTypeToClass(type)}.svg`;

    lineClone.onclick = async () => await Events.onLineClicked(line, lineClone);

    vehicleClone.querySelector(".list").append(lineClone);
  }

  Dom.content.append(vehicleClone);
}

export function addEditModal(line: Line, target: HTMLDivElement): void {
  let dialog = Dom.dialogs.edit;

  if (line.date) dialog.date.valueAsDate = new Date(line.date);
  else dialog.date.value = null;
  if (line.notes) dialog.notes.value = line.notes;
  else dialog.notes.value = null;

  dialog.self.onsubmit = async () => await Events.onEditDialogSubmitted(line);
  dialog.delete.onclick = async () => {
    dialog.self.close();
    await Events.onDeleteClicked(line, target);
  };

  dialog.self.showModal();
}

export function addLoginModal() {
  let dialog = Dom.dialogs.login;

  dialog.username.value = null;

  //  * The code below doesn't work.
  //dialog.self.oncancel = (evt: Event) => evt.preventDefault();
  dialog.self.addEventListener("cancel", (evt: Event) => {
    evt.preventDefault();
  });
  dialog.self.onsubmit = async (evt: SubmitEvent) => await Events.onLoginDialogSubmitted(evt);

  dialog.self.showModal();
}
