import * as Dom from "./dom";
import * as Display from "./display";
import * as Extensions from "./extensions";
import * as Api from "./api";
import * as Events from "./events";
import * as Store from "./store";

declare global {
  interface Window {
    Dom: unknown;
    Display: unknown;
    Extensions: unknown;
    Api: unknown;
    Events: unknown;
    Store: unknown;
  }
}

async function main() {
  await authorize();
}

async function authorize() {
  let isStored = Store.isIdentityStored();

  if (isStored) {
    let storedIdentity = Store.getIdentity();
    Api.saveIdentity(storedIdentity);

    await Events.onLoggedIn();
  } else Display.addLoginModal();
}

function registerGlobalFunctions() {
  window.Dom = Dom;
  window.Display = Display;
  window.Extensions = Extensions;
  window.Api = Api;
  window.Events = Events;
  window.Store = Store;
}

registerGlobalFunctions();
main();
