import { Line } from "./entities";
import * as Api from "./api";
import * as Dom from "./dom";
import * as Display from "./display";
import * as Extensions from "./extensions";
import * as Store from "./store";

export async function onLineClicked(line: Line, target: HTMLDivElement) {
  if (line.completed) {
    Display.addEditModal(line, target);
  } else {
    line.completed = true;
    target.dataset.completed = "true";

    await Api.set(line);
  }
}

export async function onEditDialogSubmitted(line: Line) {
  let dialog = Dom.dialogs.edit;

  line.date = dialog.date.valueAsDate;
  line.notes = dialog.notes.value || null;

  await Api.set(line);
}

export async function onDeleteClicked(line: Line, target: HTMLDivElement) {
  line.date = null;
  line.notes = null;
  line.completed = false;
  target.dataset.completed = "false";

  await Api.set(line);
}

export async function onLoginDialogSubmitted(evt: SubmitEvent) {
  let dialog = Dom.dialogs.login;
  let identity = dialog.username.value.trim();
  if (!identity) return evt.preventDefault();

  let isValid = await Api.verify(identity);
  if (isValid) {
    Api.saveIdentity(identity);
    Store.saveIdentity(identity);

    return await onLoggedIn();
  }

  alert("Neznámé jméno");
  Display.addLoginModal();
}

export async function onLoggedIn() {
  let lines = await Api.getAll();
  let eligibleLines = lines.filter((line) => !line.name.startsWith("N"));

  let groups = Extensions.groupLines(eligibleLines);

  for (let group of groups) {
    let orderedLines = Extensions.sortLines(group[1]);

    Display.addVehicle(group[0], orderedLines);
  }
}
