export const templates = {
  vehicleView: document.querySelector("template[name='vehicle-view']") as HTMLTemplateElement,
  lineView: document.querySelector("template[name='line-view']") as HTMLTemplateElement
};
export const content = document.querySelector(".content") as HTMLDivElement;
export const dialogs = {
  edit: {
    self: document.querySelector("#edit") as HTMLDialogElement,
    date: document.querySelector("#edit input[name='date']") as HTMLInputElement,
    notes: document.querySelector("#edit input[name='notes']") as HTMLInputElement,
    delete: document.querySelector("#edit .delete") as HTMLInputElement
  },
  login: {
    self: document.querySelector("#login") as HTMLDialogElement,
    username: document.querySelector("#login input[name='username']") as HTMLInputElement
  }
};
